import React from 'react';
import styles from '../../sass/components/Portfolio-Parts/Introduction.module.scss';
import { VscCode, VscTerminalCmd, VscVmActive, VscGraph, VscAccount } from "react-icons/vsc";
import { Column } from '../Global';

function Introduction(){
    return (
        <section className={styles.intro} id="expertise">
            <Column gap={1}>
                <h5>- Introduction</h5>
                <p>A highly motivated and creative software developer based in London with a strong focus on ReactJS and React Native. I am passionate about technology products that feature efficient, intuitive, and reusable code and constantly strive to find innovative solutions to development challenges.</p>
            </Column>
            <div className={styles.icon}><VscAccount /></div>
            <Column gap={1}>
                <h5>- Front-end development </h5>
                <p>An experienced technical lead with a proven track record of delivering highly scalable systems and visually appealing, innovative, and clean web-based solutions. Adept in Vanilla HTML, JSX, CSS, JS, TS, PHP, React, React Native, GraphQL, WordPress, and Git, I have a solid understanding of the full development lifecycle and a passion for programming.</p>
            </Column>
            <div className={styles.icon}><VscCode /></div>
            <Column gap={1}>
                <h5>- Back-end devlopement</h5>
                <p>As a full-stack developer with extensive commercial experience, I have a track record of delivering innovative solutions by utilizing cloud-based backends. My proficiency in Node.js, MongoDB, Express, MySQL, Firebase, and AWS enables me to create clean and dynamic web applications. I believe that a solid foundation in database design is crucial for successful front-end architecture.</p>
            </Column>
            <div className={styles.icon}><VscTerminalCmd /></div>
            <Column gap={1}>
                <h5>- UI/UX Design </h5>
                <p>As a design-focused developer, I specialize in creating hypermodern solutions that seamlessly integrate development and brand design. With expertise in modern screen design tools, I provide custom, effective outcomes that are tailored to meet the specific needs of each project.</p>
            </Column>
            <div className={styles.icon}><VscVmActive /></div>
            <Column gap={1}>
                <h5>- SEO / Optimisation</h5>
                <p>A skilled digital marketer with a proven track record of delivering effective SEO practices. With hands-on experience, I have achieved top rankings on Google under competitive searches, demonstrating my ability to drive traffic and boost online visibility for businesses.</p>
            </Column>
            <div className={styles.icon}><VscGraph /></div>
        </section>
    )
}

export default Introduction;